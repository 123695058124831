<template>
  <rb-menu-dropdown-button
    v-if="type === 'menu-dropdown-button'"
    leading-icon-name="copy"
    @click="onCopy"
  >
    {{ $t('boxes.detail.actions.copy_box_id') }}
  </rb-menu-dropdown-button>

  <rb-button
    v-else-if="type === 'button-group'"
    size="sm"
    leading-icon-name="copy"
    :alt-text="
      props.tooltipTitle ? props.tooltipTitle : $t('general.buttons.copy_to_clipboard.tooltip')
    "
    :hierarchy="props.buttonHierarchy"
    :type="props.buttonType"
    @click="onCopy"
    >{{ props.tooltipTitle }}</rb-button
  >

  <rb-tooltip
    v-else
    :class="wrapperClass"
    :title="
      props.tooltipTitle ? props.tooltipTitle : $t('general.buttons.copy_to_clipboard.tooltip')
    "
    position="top-end"
  >
    <rb-button
      size="sm"
      icon-name="copy"
      :alt-text="
        props.tooltipTitle ? props.tooltipTitle : $t('general.buttons.copy_to_clipboard.tooltip')
      "
      :hierarchy="props.buttonHierarchy"
      :type="props.buttonType"
      @click="onCopy"
  /></rb-tooltip>
</template>

<script setup lang="ts">
const { copy } = useClipboard();
const { t: $t } = useI18n();

const props = defineProps({
  type: {
    type: String as PropType<string>,
    required: false,
    default: 'button',
  },
  textToCopy: {
    type: [String, Number] as PropType<string | number>,
    required: true,
    default: '',
  },
  buttonHierarchy: {
    type: String as PropType<string>,
    required: false,
    default: 'secondary',
  },
  buttonType: {
    type: String as PropType<string>,
    required: false,
    default: 'neutral',
  },
  tooltipTitle: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  notificationTitle: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  notificationDescription: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  wrapperClass: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
});

const onCopy = () => {
  copy(`${props.textToCopy}`);
  useNotify(
    'info',
    props.notificationTitle
      ? props.notificationTitle
      : $t('general.buttons.copy_to_clipboard.notification.title'),
    props.notificationDescription
      ? props.notificationDescription
      : $t('general.buttons.copy_to_clipboard.notification.description', {
          value: props.textToCopy,
        }),
    {
      iconName: 'copy',
    },
  );
};
</script>
